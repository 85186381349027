@import '../../base/variables';
@import '../../base/media';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid $pink;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$mobile} {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }
  }

  .buttonSelected {
    border: 2px solid #a0a0a0;
  }

  .innerButton {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background-color: $pink;
  }

  .label {
    font-size: 1.25rem;
    margin-left: 1rem;
    flex: 1;

    @media #{$mobile} {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
}
