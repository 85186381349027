h2 {
  font-family: $font-header;
  font-size: 122px;

  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    font-weight: normal;
  }
}

h3 {
  font-family: $font-content;
  font-weight: bold;
}

h4 {
  font-family: $font-bold;
  letter-spacing: 1px;
}

p,
a,
li,
ol,
button {
  font-family: $font-content;
  font-size: 14px;

  @media screen and (min-width: 30em) {
    font-size: 16px;
  }
}
