// FONTS
$font-header: DrukXCondensed-Super, "DIN Condensed", "Arial Narrow";
$font-bold: Druk-Bold, DIN, Arial;
$font-content-light: MaisonNeue-Book, sans-serif;
$font-content: MaisonNeue-Medium, Arial, sans-serif;
$font-content-bold: MaisonNeue-Bold, Arial, sans-serif;
$font-typewriter: AmericanTypewriter-Regular, Consolas, "Liberation Mono", Menlo,
  Courier, monospace;

// COLORS
$grey: #f6f6f6;
$border-grey: darken(#f6f6f6, 6%);
$label-grey: darken(#f6f6f6, 40%);
$pink: #ff4074;
$light-pink: rgb(251, 215, 232);
$black: #161616;
$white: #fff;
$dark-grey: #909090;
$input-grey: #4a4a4a;
$form-border: rgb(200, 207, 216);

$font-color-dark: $black;
$footer-background: $black;
