
// Clearfix mixin
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/*
 Usuage
 .container-with-floated-children {
   @extend %clearfix;
 }
*/



// Animation Mixin
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

// @include animation('slide-down 5s 3');




// Breakpoints Mixin
@mixin bp-x-large-up {
  @media only screen and (min-width: 2800px) {
    @content;
  }
}

@mixin bp-large-up {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}

@mixin bp-small-lp-down {
    @media screen and (max-width: 1200px){
        @content;
    }
}


@mixin bp-medium {
    @media only screen and (min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}

@mixin bp-medium-down {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin bp-medium-up {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}


@mixin bp-x-small-up {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin bp-small-up {
  @media only screen and (min-width: 767px) {
    @content;
  }
}

@mixin bp-small-down {
  @media only screen and (max-width: 767px) {
    @content;
  }
}


// Flexbox Center
@mixin flex-center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


@mixin standard-before{
    position: relative;

    &:before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

@mixin drop-shadow{
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.75);
}

@mixin drop-shadow--lighter{
    box-shadow: 0px 2px 23px 0px rgba(38,50,56,0.20);
}

@mixin text-shadow{
    text-shadow: 0px 2px 4px 0px rgba(0,0,0,0.75);
}

@mixin textured-background{
    background-size: 360px 260px;
    width: 100%;
}
@mixin textured-background--green{
    background-size: 1440px 270px;
    width: 100%;
}
