@import '../../../../base/variables';
@import '../../../../base/media';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3rem 22vw;

  @media #{$mediumDown} {
    margin: 3rem 5vw;
  }
}

.header {
  margin-bottom: 2rem;

  .headerTitle {
    font-size: 1.3rem;
  }

  .headerSubTitle {
    font-size: 0.9rem;
    line-height: 1.5;
    font-family: $font-content-light;
    color: $input-grey;
  }
}

.table {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-size: 0.9rem;
  position: relative;

  .featureCol {
    flex: 2;
    padding: 2rem 3rem;
    box-shadow: -3px 3px 5px 1px #ddd;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;

    @media #{$mobile} {
      margin-top: 3rem;
      padding: 1rem 2vw;
    }

    .row {
      text-align: left;
    }
  }

  .bikePurchaseCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: -1px 3px 5px 1px #ddd;

    span {
      font-size: 1rem;
      font-weight: bold;

      @media #{$mobile} {
        font-size: 0.8rem;
      }
    }
  }

  .buzzbikeCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: 3px 3px 5px 1px #ddd;
    background: $pink;
    margin: -1rem 0;

    @media #{$mobile} {
      margin: -0.5rem 0;
    }

    .curvedHeader {
      height: 7rem;
      padding-top: 1.5rem;

      @media #{$mobile} {
        height: 4.5rem;
        padding-top: 1rem;
      }
    }

    .buzzbikeLogo {
      width: 5rem;
      height: auto;

      @media #{$mobile} {
        width: 3rem;
      }
    }

    span {
      font-size: 1.2rem;
      font-weight: bold;
      color: white;

      @media #{$mobile} {
        font-size: 0.9rem;
      }
    }
  }

  .curvedHeader {
    background-image: url('../../../../static/images/curve_with_shadow.png');
    background-size: 100% 100%;
    height: 6rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    padding-top: 1rem;

    @media #{$mobile} {
      height: 4rem;
      padding-top: 0.2rem;
    }
  }
}

.row {
  text-align: center;
  &:not(:last-child) {
    height: 3rem;
  }
}

.yesIcon {
  text-align: center;
  margin: auto;
}

.noIcon {
  margin: auto;
  width: 1rem;
  height: 1rem;
}

.bottomImage {
  width: calc(22vw + 3%);
  height: auto;
  position: absolute;
  bottom: -1rem;
  right: calc(-22vw + 3%);

  @media #{$mediumDown} {
    visibility: hidden;
  }
}

.bottomNotes {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  .note {
    font-size: 0.8rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.bottomButtons {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;

  @media #{$mobile} {
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    width: 17rem;
    height: 3rem;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$mobile} {
      width: unset;
    }

    &:hover {
      cursor: pointer;
      user-select: none;
    }
  }

  .continueButton {
    background: $pink;
    margin-right: 1rem;

    @media #{$mobile} {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .cancelButton {
    background: black;
  }
}
