@import '../../base/variables';
@import '../../base/media';

$text-font-size: 1.2;

.loadingContainer {
  width: 100vw;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 20vw 2rem 20vw;
  align-items: stretch;

  @media #{$mediumDown} {
    padding: 100px 10% 2rem 10%;
  }

  @media #{$mobile} {
    padding: 100px 5vw 2rem 5vw;
  }

  .title {
    font-size: 3rem;
    letter-spacing: 2px;
    margin-bottom: 1rem;
    font-family: $font-content-bold;

    @media #{$mobile} {
      font-size: 2rem;
    }
  }

  .excerpt {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 3rem;
    font-family: $font-content-light;

    @media #{$mobile} {
      font-size: 1rem;
    }
  }

  .mainImage {
    height: auto;
    max-height: 80vh;
    margin-bottom: 3rem;
    object-fit: cover;

    @media #{$mobile} {
      margin-bottom: 2rem;
      max-height: 50vh;
    }
  }

  .author {
    margin-bottom: 1.5rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-family: $font-content-light;
    margin-bottom: 2rem;
  }

  .footer {
    margin-bottom: 2rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-content-bold;
    color: $pink;
  }

  h1 {
    font-size: ($text-font-size * 2) + rem !important;
  }

  h2 {
    font-size: ($text-font-size  * 1.5) + rem;
  }

  h3 {
    font-size: ($text-font-size  * 1.17) + rem;
  }

  h4 {
    font-size: ($text-font-size  * 1) + rem !important;
  }

  h5 {
    font-size: ($text-font-size  * 0.83) + rem;
  }

  h6 {
    font-size: ($text-font-size  * 0.67) + rem;
  }
}
