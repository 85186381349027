@import '../../base/variables';
@import '../../base/media';

.container {
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  display: flex;
  align-items: stretch;
  height: 3rem;
  margin-top: 2rem;

  .input {
    flex: 1;
    border: 1px solid $pink;
    margin-right: 1rem;
    font-size: 1rem;
    color: black;
    padding: 0 1rem;

    &:focus {
      outline: 1px solid $pink;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 2rem;
    white-space: nowrap;
    background-color: $pink;

    &:disabled {
      background: #dddddd;
      cursor: not-allowed !important;
    }

    @media #{$mobile} {
      padding: 0 1rem;
    }

    &:hover {
      cursor: pointer;
      user-select: none;
    }
  }
}

.error {
  color: $pink;
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 1rem;
}

.successMessage {
  color: $pink;
  font-size: 1rem;
  line-height: 1.4;
}
