@import '../../../base/variables';
@import '../../../base/media';

$text-font-size: 1.2;
$text-font-size-mobile: 1;

.paragraph {
  display: block;
}

.span {
  font-size: $text-font-size + rem;
  line-height: 1.4;
  font-family: $font-content-light;

  @media #{$mobile} {
    font-size: $text-font-size-mobile + rem;
  }
}

.list {
  margin-left: 2rem;
  padding-left: 1rem;

  li {
    @extend .span;
    margin: 1rem 0;
  }
}

.link {
  color: $pink;
  text-decoration: underline;
  font-size: $text-font-size + rem;

  @media #{$mobile} {
    font-size: $text-font-size-mobile + rem;
  }
}

.lastItem {
  margin-bottom: 0;
}

.quote {
  font-family: $font-content;
  margin-left: 2rem;
  font-size: 1.875rem;

  @media #{$mobile} {
    margin-left: 1rem;
    font-size: 1.5rem;;
  }
}

.sectionDivider {
  margin-left: calc(50% - 1.875rem);
  width: 3.75rem;
  height: auto;
}

.listNoStyle {
  list-style-type: none;
  padding: 0 !important;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.tableHeadRow {
  background: $pink;
  color: $white;
}

.tableBodyRow {
}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media #{$mobile} {
    overflow-x: scroll;
  }

  table {
    table-layout: fixed;
    width: 100%;

    @media #{$mobile} {
      table-layout: unset;
      width: unset;
    }
  }

  table, th, td {
    border: 1px solid $pink;
    border-collapse: collapse;
  }

  th, td {
    vertical-align: top;
    padding: 1rem;

    @media #{$mobile} {
      margin-left: 0.5rem;
      font-size: 0.5rem;;
    }

    min-width: 10rem;
  }

  th {
    &:not(:last-child) {
      border-right: 1px solid white;
    }
  }
}

.heading {
  display: block;
}
