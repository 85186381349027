@import "../../../../base/variables";
@import "../../../../base/media";

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3rem 22vw;

  @media #{$regularDown} {
    margin: 3rem 15vw;
  }

  @media #{$mediumDown} {
    margin: 3rem 5vw;
  }

  .header {
    margin-bottom: 2rem;

    .headerTitle {
      font-size: 1.3rem;
    }

    .headerSubTitle {
      font-size: 0.9rem;
      line-height: 1.5;
      font-family: $font-content-light;
      color: $input-grey;
    }
  }

  .contactItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media #{$mobile} {
      flex-direction: column;
      align-items: stretch;
    }

    .contactItemContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media #{$mobile} {
        margin-bottom: 1rem;
      }

      .contactItemIcon {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
      }

      .contactItemText {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .bottomButtons {
    display: flex;
    flex-direction: row;
    margin: 3rem 0;

    @media #{$mobile} {
      flex-direction: column;
      align-items: stretch;
    }

    .button {
      width: 17rem;
      height: 3rem;
      color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media #{$mobile} {
        width: unset;
      }

      &:hover {
        cursor: pointer;
        user-select: none;
      }
    }

    .helpButton {
      background: $pink;
      margin-right: 1rem;

      @media #{$mobile} {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .cancelButton {
      background: black;
    }
  }
}
