@import '../base/media';

.sign-up-container {
  width: 33.3333%;
  min-width: 450px;
  margin-left: auto;
  margin-right: auto;

  @media #{$wide} {
    max-width:650px;
  }

  @media #{$tablet}, #{$medium} {
    min-width:unset;
    width:364px;
  }

  @media #{$mobile} {
    min-width:unset;

    width:86vw;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 4rem;
    margin-top: 5rem;

    @media #{$mobile} {
      font-size: 1.4rem;
      font-weight: 900;
      margin-top: 2rem;
      margin-bottom:2rem;
    }
  }

  /*
  @media screen and (max-width: 59em) {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
  */
}

.sign-up-login-section {
  text-align: center;
  margin-top: 40px;
}
