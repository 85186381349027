@import '../../base/variables.scss';
@import '../../base/media';

.container {
  padding: 1rem;
  border: 1px solid $form-border;
  background: $white;
  border-radius: 4px;
  width: 100%;
  color: $input-grey;
  outline: none;
  max-height: 3rem;

  &:focus {
    box-shadow: 0 0 2px 1px $pink;
  }

  &::placeholder {
    color: $form-border;
  }
}

.disabledContainer {
  &:hover {
    cursor: not-allowed;
  }
}
