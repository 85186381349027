@import '../../base/variables';
@import '../../base/media';

.container {
  display: flex;
  flex-direction: row;

  .inputsContainer {
    display: flex;
    flex-direction: row;
    margin-right: 2rem;

    @media #{$mediumDown} {
      flex-direction: column;
    }

    .inputContainer {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 2rem;
      }

      @media #{$mediumUp} {
        &:not(:first-child) {
          margin-top: 0;
          margin-left: 2rem;
        }
      }
    }
  }

  .switchButton {
    color: $pink;
    text-decoration: underline;
  }
}
