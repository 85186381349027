@import '../../base/variables';
@import '../../base/media';

.loadingContainer {
  width: 100vw;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  padding: 150px 20vw 4rem 20vw;
  align-items: stretch;

  @media #{$mediumUp} {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 5vw;
    padding: 150px 10vw 4rem 10vw;
  }

  @media #{$mediumDown} {
    padding: 150px 10% 4rem 10%;
  }

  @media #{$mobile} {
    padding: 150px 5vw 4rem 5vw;
  }

  .listItem {
    &:not(:last-child) {
      padding-bottom: 4rem;
      margin-bottom: 4rem;
      border-bottom: 1px solid $border-grey;

      @media #{$mobile} {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  .firstListItem {
    grid-area: 1 / 1 / span 1 / span 2;
  }
}
