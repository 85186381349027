//fixes problem causes by resources/index.scss
p {
  display: block;
}
@import '~normalize.css/normalize.css';
@import '~slick-carousel/slick/slick.css';
@import '~tachyons/css/tachyons.css';
@import './base/_fonts.scss';
@import './base/_variables.scss';
@import './base/_typography.scss';
@import './base/_base.scss';
@import './base/_mixins.scss';


html:not([data-scroll='0']) {

  /*
  .custom_fc_frame {
    //  right: 2px !important;
    -webkit-transition: opacity 1.0s ease-in;
    -moz-transition: opacity 1.0s ease-in;
    -o-transition: opacity 1.0s ease-in;
    opacity: 1 !important;
  }
  */
}

.custom_fc_frame {
  //  right: 2px !important;
  /*
  opacity: 0 !important;
  */

}



