.Button {
  color: white;
  cursor: pointer;
  border: none;
  line-height: 1;
  padding-top: 1.2rem;
}

.loading-indicator {
  position: absolute;
  left: 0.5rem;
}
