@import '../../base/variables.scss';

.CheckoutBoxes {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top:0.6rem;
  &-input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: inherit;
  }
}

.button:disabled {
  background: #dddddd;
}
