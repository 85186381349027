@import '../../base/variables';
@import '../../base/media';

.LogIn {
  &-form {
    margin-top: -1rem;
    height: 100%;
  }
  &-button {
    background: $pink;
    border-radius: 4px;
    padding: 1rem 3rem;
    min-width: 12rem;

    @media #{$tablet} {
      width:50vw;
    }

    @media #{$mobile} {
      width:86vw;
    }

  }
  &-noAccount {
    margin: 3rem 0 10rem 0;
    color: $input-grey;
  }

  &-remember {
    @media screen and (max-width: 30em) {
      margin-top: 3.5rem;
      margin-bottom: 1.5rem;
    }
    @media screen and (min-width: 30em) {
      float: left;
      margin-top: 1rem;
    }
  }

  &-forgot {
    width:100%;
    text-align:right;
    margin-top: 1.1rem;
    margin-bottom: 0.2rem;
    padding-bottom: 0.3rem;
    color: $input-grey;
    letter-spacing: 0.02em;
    min-height: 1.5rem;

    &-link {
      display:inline !important;
      text-align:right !important;
      font-size: 16px;
    }

    @media screen and (max-width: 30em) {
      horiz-align:left;
      text-align:left;
      margin-top:-5.5rem;
      margin-bottom:2.7rem;
    }
  }
}
