@import "../../base/variables";

.Navbar {
  position: fixed;
  top: 0;
  z-index: 11;
  transition: all 0.3s ease;
  font-size: 20pt !important;
  width: 100%;

  &.active {
    bottom: 0;
  }

  h1 {
    margin: 0;
    margin-left: 0.5rem;
    height: auto !important;
    z-index: 10000;
    @media screen and (max-width: 959px) {
      position: fixed;
      top: 10px;
      left: 10px;
    }
  }

  .navbarLogo {
    @media screen and (max-width: 959px) {
      visibility: hidden;
    }
  }
  .pageLogo {
    @media screen and (min-width: 960px) {
      visibility: hidden;
    }
  }

  a {
    white-space: nowrap;

    @media screen and (max-width: 959px) {
      &.ButtonLink {
        background-color: white;
        text-transform: capitalize;
        text-align: left;
        display: flex;
        align-items: center;
        width: 100%;
        color: black;
      }
    }
  }

  .nav-link::before,
  a.ButtonLink::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background: transparent;
    transition: all 0.15s ease-in-out;
    transition-property: background-color;
  }

  .nav-link:hover,
  a.ButtonLink:hover {
    @media (min-width: 960px) {
      color: #ff4074;
    }

    @media (max-width: 959px) {
      background-color: rgb(255, 229, 243);
    }
    &.bg-pink {
      @media (min-width: 960px) {
        color: $input-grey;
      }
      @media (max-width: 959px) {
        color: black;
      }
    }
  }

  @media (max-width: 959px) {
    .nav-link:hover::before,
    a.ButtonLink:hover::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 6px;
      background: #ff4074;
    }
  }

  .openNav {
    display: none;
  }

  .closePopUp {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 42px;
    width: 42px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-left: 13px;
      margin-top: 13px;
    }

    @media screen and (min-width: 960px) {
      display: none;
    }
  }

  &__content {
    transform: translateX(-100%);
    background-color: white;
    transition: all 0.3s ease;
    //    height: 90vh;
    width: 100%;
    height: 100%;
    padding-top: 70px !important;

    @media screen and (min-width: 960px) {
      padding-top: 10px !important;
      padding-left: 40px !important;
      padding-right: 40px !important;
      max-width: none;
      width: 100%;
      //transform: translateX(00%);
      // height: 100vh;
    }

    @media screen and (min-width: 960px) {
      height: auto;
    }

    @media screen and (max-width: 959px) {
      position: fixed;
    }

    .ButtonLink.bg-white {
      color: black;
    }

    .ButtonLink.has-border {
      border-radius: 8px;
      border: solid 1px #171717;
    }

    &.active {
      transform: translateX(0);

      .social-links {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.smaller-nav {
      transition: all 0.3s ease;
      @media screen and (min-width: 960px) {
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem;
      }
    }
  }

  .nav-container {
    @media screen and (min-width: 960px) {
      padding-top: 0;
    }
  }

  .nav-link,
  .ButtonLink {
    transition: all 0.15s ease-in-out;
    transition-property: background-color;
    position: relative;

    &.button {
      @media screen and (min-width: 960px) {
        width: 115px;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 46px;
      }

      @media screen and (max-width: 959px) {
        padding-left: 15px;
      }
    }

    &.link {
      @media screen and (min-width: 960px) {
        min-width: 115px;
      }
      @media screen and (max-width: 959px) {
        padding-left: 15px;
      }
    }

    @media screen and (max-width: 1100px) and (min-width: 480px) {
      //      padding-left: 3rem !important;
      //      padding-right: 3rem !important;
    }
  }

  .burger {
    position: fixed;
    top: 5vw;
    right: 5vw;
    @media screen and (min-width: 480px) {
      top: 25px;
      right: 25px;
    }
    cursor: pointer;
  }

  .burger-bar {
    height: 3px;
    width: 25px;
    background-color: white;
    margin: 3px;
  }

  @media screen and (min-width: 960px) {
    &__content {
      background-color: transparent;
      transform: translate(0);
    }

    .nav-link {
      color: white;
    }
  }

  @media screen and (max-width: 959px) {
    &__content {
      .ButtonLink {
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    &__content {
      .ButtonLink {
        font-size: 14px;
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
.Navbar.nav-bg {
  @media screen and (max-width: 959px) {
    transition: background-color 0.1s ease;
    background: rgba(255, 255, 255, 1);
    width: 100%;
    height: 70px;
  }

  .Navbar__content {
    @media screen and (min-width: 960px) {
      transition: all 0.1s ease;
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .burger-bar {
    background-color: black;
  }
}
@media screen and (max-width: 959px) {
  .nav-link,
  .ButtonLink {
    width: 100%;
    height: 65px;
    text-transform: capitalize;
  }
}
.nav-container {
  width: 100%;
  @media (min-width: 960px) {
    justify-content: flex-end;
  }
}

.nav-link.nav-current {
  @media (max-width: 959px) {
    transition: all 0.1s ease;
    background: rgba(255, 4, 139, 0.3);
    border-left: 5px solid #ff4074;
  }
}

.nav-current.nav-link {
  @media (min-width: 960px) {
    color: #ff4074;
  }
}

.Account {
  &-menu {
    display: flex;
    align-items: center;
  }

  &-container {
    min-width: 10rem;
    margin-left: 1rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;

    &:after {
      content: "";
      width: 1px;
      height: 18px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 11px;
    }
  }
  &-icon {
    margin-top: 3px;
  }

  &-popup {
    position: absolute;
    right: 0;
    top: 50px;
    background: rgba(255, 255, 255, 0.75);
    width: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
    border-radius: 2px;

    &-link {
      color: black;
      padding: 0.5rem 0;

      &:hover {
        color: $pink;
      }
    }

    &-triangle {
      position: absolute;
      top: -10px;
      right: 16px;
    }
  }
}
.social-links {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 20px;
  opacity: 0;
  pointer-events: none;

  @media screen and (max-width: 959px) {
    display: block;
  }

  .social-link {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
  }
}

.social-link.social-link--facebook {
  background-image: url("./icon-facebook.svg");
  background-size: 11px 20px;
}

.social-link.social-link--instagram {
  background-image: url("./icon-instagram.svg");
  background-size: 19px 19px;
}

.social-link.social-link--twitter {
  background-image: url("./icon-twitter.svg");
  background-size: 21px 18px;
}

.social-link.social-link--vimeo {
  background-image: url("./icon-vimeo.svg");
  background-size: 23px 20px;
}

.social-link.social-link--youtube {
  background-image: url("./icon-youtube.svg");
  background-size: 27px 19px;
}

.Navbar-blog-label {
  font-size: 1.125rem;
  position: absolute;
  top: 5px;
  right: -2rem;
  font-family: $font-typewriter;
  font-weight: normal;
  color: #414141;

  &-content {
    visibility: hidden;
    @media screen and (min-width: 960px) {
      visibility: visible;
    }

    &-scrolled {
      color: $white;
    }
  }
}

.Navbar-login-button {
  @media screen and (min-width: 960px) {
    border-radius: 8px;
    border: solid 1px #171717 !important;
  }
}

.Navbar-signup-button {
  @media screen and (min-width: 960px) {
    border-radius: 8px;
  }
}

.nav-link-blog {
  color: $black !important;
}
