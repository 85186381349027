@import '../../base/_variables.scss';
.Field {
  flex: 1;
  position: relative;
  padding-bottom: 10px;
}

.FieldWrap {
  @media screen and (max-width: 30rem) {
    display: block;
    margin-bottom: 20px;

    > div {
      width: 100%;
    }
  }
}
.FieldWrap .Field:first-child {
  margin-right: 0px;

  @media screen and (min-width: 30rem) {
    margin-right: 20px;
  }
}

.Field input,
.Field select,
.Field .Field__label-text {
  color: $label-grey;
  font-size: 20px;
  font-weight: 200;
  outline-color: $pink;
  font-family: $font-content-light;
  p {
    color: $label-grey;
  }
}
.Field--type-checkbox .Field__label-text {
  margin-left: 10px;
}
.Field--label-top .Field__label-text {
  margin-bottom: 5px;
}
.Field input,
.Field select,
.Field textarea {
  width: 100%;
  padding: 8px 10px;
  border-radius: 3px;
  background: white;
  border: 2px solid $border-grey;
}
.Field__arrow {
  display: inline-block;
  z-index: 1;
  fill: black;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate3d(-5px, -7px, 0px);
  transform-origin: 50% 50%;
  svg {
    transform: rotate(90deg) translateX(5px);
  }
  &:before {
    content: '';
    border-left: 2px solid $border-grey;
    position: absolute;
    left: -5px;
    top: -2px;
    bottom: 0px;
    height: 40px;
    width: 2px;
  }
}
.Field select {
  appearance: none;
  -webkit-appearance: none;
}
.Field label {
  cursor: pointer;
}
.Field input[type='checkbox'] {
  width: auto;
  margin-top: 5px;
}
.Field--label-right label,
.Field--label-left label {
  display: flex;
  flex-flow: row;
}

input[type='date'] {
  height: 42px;
}

.Field .react-datepicker-wrapper,
.Field .react-datepicker__input-container {
  width: 100%;
  background: white;
}
.Field .react-datepicker__triangle {
  border-bottom-color: white !important;
}
.Field .react-datepicker__header {
  background: white;
}
.Field .react-datepicker__day {
  color: $label-grey;
}
.Field .react-datepicker__day--keyboard-selected {
  color: white;
}
.Field .react-datepicker__day--selected {
  background: $pink;
  color: white;
}

.Field-error {
  position: absolute;
}
.Field-error,
.Field-error * {
  color: $pink;
  font-size: 12px;
}
.Field--type-checkbox .Field-error {
  margin-top: 25px;
}

.tooltip-wrapper {
  float: right;
  position: absolute;
  height: 18px;
  width: 18px;
  top: -5px;
  margin-left: 5px;
  z-index: 8;
}

.__react_component_tooltip {
  max-width: 250px;
  // background-color: $label-grey !important;
  padding: 8px;
}

.react-datepicker-popper {
  z-index: 10;
}
