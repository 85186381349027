.PinkBanner {
  width: 100%;
  height: 120px;
  position: absolute;
  transform: translateY(-50%);
  bottom: 15%;
  left: 0;

  @media screen and (min-width: 60rem) {
    top: 51%;
  }

  .pink-line {
    height: 30px;
    width: 100%;
    left: 0;
    opacity: 0.5;
    background-color: #ff4074;
    position: absolute;

    &:nth-child(1) {
      top: 0px;
      opacity: 0.3;
    }
    &:nth-child(2) {
      top: 30px;
    }
    &:nth-child(3) {
      top: 30px;
    }
    &:nth-child(4) {
      top: 60px;
    }
    &:nth-child(5) {
      top: 60px;
    }
    &:nth-child(6) {
      top: 60px;
    }
    &:nth-child(7) {
      top: 60px;
    }
    &:nth-child(8) {
      bottom: 0px;
    }
  }
}
