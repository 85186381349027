@import '../../../base/media';
@import '../../../base/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #737373;
  padding: 0.3rem 0;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    user-select: none;
    opacity: 0.6;
  }
}

.selectedContainer {
  border: 1px solid $pink;
  background: $pink;

  span {
    color: white;
  }

  @media (hover: hover) {
    &:hover {
      cursor: not-allowed;
      user-select: none;
      opacity: 1;
    }
  }
}
