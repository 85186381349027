@import '../../base/variables';
@import '../../base/media';


.Header {
  position: relative;

  > div {
    background-size: cover;
    background-position: center;
    overflow: hidden;
    @media #{$mobileUp} {
      position: relative;
    }


  }


  &.noButtonHeader {
    h2 {
      margin-bottom: 110px;
      @media screen and (min-width: 60rem) {
        margin-bottom: 0;
      }
    }
  }

  &.b2w .header-bg img {
    object-position: top center !important;
  }

  h2 {
    font-size: 115px;
    line-height: 95px;
    font-weight: normal;
    padding-bottom: 20px;
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
      line-height: 85px;
    }
    @media screen and (min-width: 30rem) {
      font-size: 122px;
      line-height: 105px;
      padding-bottom: 0;
    }
  }

  a,
  button {
    position: absolute;
    left: 0;
    left: 0;
    @media screen and (max-width: 30rem) {
      width: 90vw;
    }
  }

  video {
    position: absolute;
    left: 50%;
    top: calc(50% - 1px);
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
  }

  .header__content {
    z-index: 10;
    position: relative;
    @media screen and (max-width: 60rem) {
      padding-bottom: 10rem;
    }
  }

  &.noBanner {
    .PinkBanner {
      display: none !important;
    }
  }

  &.home {
    h2 {
      margin-top: -105px;
    }

    .image-wrapper {
      @media #{$mediumDown} {
        height: fit-content;
        min-height: calc(max(477px, 125vw));
      }

      @media #{$mediumUp} {
        height: fit-content;
        min-height: calc(max(477px, 67vw));

      }
    }

    img {
      width:100%;
      height: 100%;
      top:0;
      filter: brightness(60%);
      min-height:477px;
    }

    @media screen and (max-width: 30rem) {
      .PinkBanner {
        bottom: 6%;
      }

      h2 {
        padding-bottom: 15px;
        font-size: 75px;
        line-height: 60px;
      }

      .header__content {
        padding-bottom: 6.2rem;
      }
    }
  }

  &.whats-included {
    img {
      width:100%;
      top:0;
      filter: brightness(85%);
    }

    .image-wrapper {
      @media #{$mediumDown} {
        height: fit-content;
        min-height: calc(max(477px, 179vw));
      }
    }
  }

  &.noButtonHeader {
    .header__content {
      @media screen and (max-width: 60rem) {
        padding-bottom: 4rem;
      }
    }
  }

  &.third-height {
    & > div {
      height: 33vh !important;
      min-height: 250px;
    }
  }

  &.half-height {
    & > div {
      align-items: center !important;
      height: 50vh !important;
      min-height: 250px;
    }
  }

  &.half-height,
  &.third-height {
    .header__content {
      @media screen and (max-width: 60rem) {
        padding-bottom: 0;
      }
    }
  }

  .gatsby-image-outer-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
  }

  .header-bg {
    width: 100%;
    height: 100%;
    position: absolute;

  }
  @keyframes bounce {
    0% {
      transform: translateY(0) translateX(-50%) rotate(90deg);
    }

    50% {
      transform: translateY(-8px) translateX(-50%) rotate(90deg);
    }

    100% {
      transform: translateY(0px) translateX(-50%) rotate(90deg);
    }
  }

  .pinkArrow {
    display: none;
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 20px;
    animation: bounce 1.5s infinite;
  }

  &.home .pinkArrow {
    display: block;
    @media screen and (min-width: 30rem) {
      display: none;
    }
  }
}
