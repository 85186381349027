@import '../../../../base/variables';
@import '../../../../base/media';

.container {
  display: flex;
  flex-direction: column;
  margin: 3rem 0 3rem 20vw;

  @media #{$mediumDown} {
    margin-left: 5vw;
  }

  .header {
    margin: 0 3rem 3rem 0;

    .headerTitle {
      font-size: 1.3rem;
    }

    .headerSubTitle {
      font-size: 0.9rem;
      line-height: 1.5;
      font-family: $font-content-light;
      color: $pink;
    }
  }

  .table {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .leftCol {
      width: 25vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem;
      box-shadow: 1px 3px 9px #A5A5A580;

      @media #{$mediumDown} {
        width: unset;
        flex: 2;
        padding: 1rem;
      }

      .row {
        text-align: left;
      }
    }

    .rightCol {
      width: 15vw;
      background: $pink;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 2px 4px 12px #00000080;

      @media #{$mediumDown} {
        width: unset;
        flex: 1;
        margin-right: 5vw;
      }
    }

    .row {
      text-align: center;
      &:not(:last-child) {
        height: 3rem;
      }
    }

    .yesIcon {
      text-align: center;
      margin: auto;
    }

    .imageCol {
      flex: 1;
      display: flex;
      align-items: flex-end;

      @media #{$mediumDown} {
        flex: 0;
      }

      img {
        margin-left: -5%;
        width: 115%;
        max-width: 40vw;
        height: auto;
      }
    }
  }

  .bottomButtons {
    display: flex;
    flex-direction: row;
    margin: 3rem 0;

    @media #{$mobile} {
      flex-direction: column;
      align-items: stretch;
      margin-right: 5vw;
    }

    .button {
      width: 17rem;
      height: 3rem;
      color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media #{$mobile} {
        width: unset;
      }

      &:hover {
        cursor: pointer;
        user-select: none;
      }
    }

    .claimButton {
      background: $pink;
      margin-right: 1rem;

      @media #{$mobile} {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .cancelButton {
      background: black;
    }
  }
}
