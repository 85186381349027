@import "../../base/media";
@import "../../base/variables";

.UserDetailsPage {
  margin-bottom: 7rem;
}

.UserDetailsLoadingContainer {
  width: 100%;
  height: 14rem;
  margin-bottom: -7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delDateMsg {
  font-size: small;
  font-style: italic;
}

.UserDetails {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-tab {
    color: $white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-content;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 1px;
    user-select: none;
    background: rgb(251, 215, 232);

    @media screen and (max-width: 59em) {
      border-radius: 0;
      padding: 24px;
      font-size: 1.12rem;
      font-weight: bold;
      display: none;
      text-align: center;
    }

    &-wrapper {
      display: grid;
      grid-template: 3rem / repeat(7, 10rem);
      place-content: center;

      margin: 4rem 0 3rem 0;
      gap: 1.5rem;

      @media screen and (max-width: 59em) {
        display: block;
        margin: 0;
      }
    }
  }

  &-tab.tab-active {
    background: #{$pink};
    @media screen and (max-width: 59em) {
      display: block;
    }
  }

  &-content {
    display: grid;
    column-gap: 8rem;
    grid-template: auto auto / minmax(auto, 18rem) minmax(auto, 29rem);
    justify-content: space-between;
    flex: 1;

    @media screen and (max-width: 59em) {
      display: block;
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &-header {
    text-align: left;
    grid-column: 1 / 3;
    grid-row: 1;
    margin: 3rem 0 1rem 0;
    font-size: 1.75rem;
  }

  &-subheader {
    font-size: 1.75rem;
    text-align: left;
    grid-column: 2;
    grid-row: 1;
    margin: 3rem 0 1rem 0;
  }

  &-copy {
    font-size: 1rem;
    line-height: 200%;
    text-align: left;
    grid-column: 1;
    grid-row: 2;
    letter-spacing: 0.015em;

    @media #{$mobile} {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: underline;
      color: #ff4074;
    }
  }

  &-form {
    &-wrapper {
      grid-column: 2;
      grid-row: 2;
      @media #{$mobile} {
        width: 86vw;
      }
    }
  }

  &-welcome {
    margin: 2rem auto 12rem auto;
    display: grid;
    grid-template: auto auto auto / minmax(auto, 58rem);
    place-content: center;
    text-align: center;
    &-header {
      font-weight: bold;
      font-size: 2rem;
    }

    &-copy {
      line-height: 2;
    }
    @media #{$mobile} {
      width: 86vw;
    }
  }

  &-progress-step-container {
    display: none;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 59em) {
      display: flex;
      margin-left: 20px;
      margin-right: 20px;
      flex-direction: row;
    }
  }

  &-progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;

    span {
      font-size: 1.1rem;
      margin-top: 10px;
      opacity: 0.7;
    }
  }

  &-progress-spacer-step {
    flex-grow: 1;
    position: relative;
  }

  &-progress-spacer-step::after {
    content: "";
    position: absolute;
    top: 10px;
    height: 1px;
    left: -12px;
    right: -12px;
    background: #{$pink};
  }

  &-check-icon {
    position: relative;
    display: block;
    border: 1px solid #{$pink};
    border-radius: 20px;
    width: 20px;
    height: 20px;
  }

  &-check-icon.check-active {
    background: #{$pink};
  }

  &-check-icon.check-active::after {
    content: "";
    color: white;
    position: absolute;
    margin-left: 4px;
    margin-top: 5px;
    width: 10px;
    height: 6px;
    top: 0;
    left: 0;
    border-bottom: solid 1px currentColor;
    border-left: solid 1px currentColor;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.CustomSubmit {
  &-2 {
    grid-auto-flow: column;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 3rem;
  }
  &-1 {
    display: flex;
    margin-bottom: 3rem;
    //    grid-template-columns: repeat(1, 1fr);
  }

  &-button {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    @media #{$mobile} {
      height: 48px;
      padding: 5px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      vertical-align: middle;
    }
  }

  &-button:disabled {
    background: #dddddd;
  }

  &-copy {
    width: 100%;

    &-smaller {
      @media #{$mobile} {
        font-size: 4vw;
        white-space: normal;
        height: unset;
        //  display:block;
        //  white-space: nowrap;
        //  overflow: hidden;
        //  text-overflow: ellipsis;
      }

      @media screen and (max-width: 350px) {
        font-size: 10pt;
      }
    }

    &-left {
      &-arrow {
        padding-bottom: 3px;
        fill: white;
        height: 28px;
        width: 28px;
      }
      &-text {
        &-smaller {
          @extend .CustomSubmit-copy-smaller;
        }
      }
    }
    &-right {
      &-arrow {
        padding-bottom: 3px;
        fill: white;
        height: 28px;
        width: 28px;
      }
      &-text {
        &-smaller {
          @extend .CustomSubmit-copy-smaller;
        }
      }
    }
  }
}

.Terms {
  &-header {
    text-align: left;
    color: $input-grey;
  }

  &-wrapper {
    border: 1px solid $form-border;
    padding: 1rem;
    color: $input-grey;
    border-radius: 4px;
    text-align: left;
    line-height: 1.25;
    overflow: scroll;
    max-height: 40vh;
  }
}

.referer-code-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;

  @media #{$mediumDown} {
    flex-direction: column;
  }
}

.referer-code-text {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-weight: bold;
  color: $pink;
  padding: 1rem;
  text-align: center;
  margin-left: 1rem;

  &-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
  }

  @media #{$mediumDown} {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.or-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #acacac;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0.09em;
    width: 50%;
  }

  &::before {
    background: #d8d8d8;
    margin-right: 4vh;
  }

  &::after {
    background: #d8d8d8;
    margin-left: 4vh;
  }
}
