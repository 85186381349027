.SocialMediaShareButton {
  height: 1.5rem;
  width: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  margin-right: 2rem;
  cursor: pointer;

  &--facebook {
    background-image: url('../../components/Navbar/icon-facebook.svg');
  }
  &--twitter {
    background-image: url('../../components/Navbar/icon-twitter.svg');
  }
  &--email {
    background-image: url('../../components/Navbar/icon-email.svg');
  }
  &--email {
    background-image: url('../../components/Navbar/icon-email.svg');
  }
  &--whatsapp {
    background-image: url('../../components/Navbar/icon-whatsapp.svg');
  }
}
