@import '../../base/variables';
@import '../../base/media';

.Account {
  min-height: 75vh;

  &-tabs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    max-width: 550px;
    gap: 2rem;
    text-align: center;
    place-content: center;

    margin: 3rem auto;

    @media #{$mediumDown} {
      gap: 2rem;
      margin: 2rem auto;

    }

    @media screen and (max-width:600px) {
      grid-gap: 1rem;
      margin: 2rem auto;
      max-width: 80vw;
    }


    @media #{$mobile} {
      gap: 0;
      grid-gap:1rem;
      max-width: 95vw;
      margin-left:15px;
      margin-right:15px;
    }
  }

  h2 {
    font-size: 1.2rem;
    font-family: $font-content;
    margin-top:1rem;

  }

  &-header {
    font-size: 1.5rem;

  }

  &-tab {
//    padding: 0rem 0;
    cursor: pointer;
    &--active {
      border-bottom: 4px solid $pink;
    }

    &-title {
      white-space: nowrap;

      @media screen and (min-width:425px ) and ( max-width: 535px) {
        white-space: unset;
        font-size: 11pt;
        color:red;
      }

      @media screen and (max-width: 535px) {
        display:none
      }
    }

    &-title-alt {
      display:none;
      font-size:11pt;

      @media screen and (max-width: 535px) {
        white-space: unset;
        display:block
      }

      @media #{$mobile} {
        font-size: 9pt;
      }
    }
  }

  &-error {
    height: 5rem;
    width: 100%;
    vertical-align: center;
    text-align: center;
    color: $pink;
    margin-top: 2rem;
    font-size: 20px;
    padding-top: 2rem;
  }

  &-data {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    display: flex;
//    justify-content: space-around;
    padding: 2rem 3rem;
    @media screen and (max-width: 549px) {
      padding: 2rem;
      flex-direction: column;
    }


    &-wrapper {
      margin: 3rem auto;
      max-width: 45rem;
      width: fit-content;
      @media #{$mediumDown} {
        width: auto;
        max-width:90vw;
      }
    }

    &-subscription {
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;

      @media screen and (min-width: 550px) {
        width: 450px;
      }

      @media screen and (max-width: 549px) {
        max-width: 90vw;
      }
    }


    &-single  {
      display: flex;
      flex-direction: column;

      &-horizontal {
        display: grid;
        grid-auto-flow: column;
      }
    }

    &-col1 {
      @media screen and (min-width: 550px)  {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
      flex: 1;
      display: grid;
      padding-right:4rem;
      gap: 2rem;
      text-align: left;
    }
    &-col2 {
      flex: 1;
      display: grid;
      gap: 2rem;
      text-align: left;
      padding-left: 4rem;
      @media screen and (max-width: 549px)  {
        padding-left: 0;
        margin-top: 2rem;
      }
    }

    &-label {
      color: $label-grey;
      margin-bottom: 0.5rem;
      line-height: 1.5;
    }

    &-value {
      min-height: 1rem;
      line-height: 1.5;
    }
  }

  &-profile {

    @media screen and (min-width: 550px) {
      min-width: 450px;
      margin-left: auto;
      margin-right: auto;

    }

    @media screen and (max-width: 549px) {
    }

  }

  &-subscription {

    @media screen and (min-width: 550px) {
      min-width: 450px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-around;


    }

    @media screen and (max-width: 549px) {
      max-width: 90vw;
    }


    &-info {
      display: grid;
      grid-gap: 3px;
      grid-template-rows: auto;
      grid-template-columns: 100px auto;
      grid-auto-flow: row;

      @media screen and (max-width: 549px) {
        width: 90vw;

      }

      @media screen and (min-width: 550px) {
        min-width: 450px;
      }
      padding: 2rem;
      background: rgba(0, 0, 0, 0.03);
      border-radius: 6px;

    }

  }

  &-cancel-subscription {
    margin-bottom: 10rem;
    margin-top: 1rem;
    color: $pink;
    font-weight: bold;
  }

  &-payment {
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 550px) {
      width: 450px;

    }

    @media screen and (max-width: 549px) {
      max-width: 90vw;
    }


    &-info {
      grid-template-areas: 'firstName' 'cardNo' 'expiry' 'edit';
      grid-template-rows: auto;
      grid-template-columns: auto;

      @media screen and (max-width: 549px) {
        width: 90vw;

      }

      @media screen and (min-width: 550px) {
        min-width: 450px;
      }
      padding: 2rem;
      background: rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      display: inline-grid;




      gap: 2rem;
    }
    &-firstName {
      grid-area: firstName;
    }
    &-cardNo {
      grid-area: cardNo;
    }
    &-expiry {
      grid-area: expiry;
    }

    &-edit {
      grid-area: edit;
      background: black;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      justify-self: self-start;
      width: 90%;
      margin-left: 5%;
      padding: 1rem 0;
      text-align: center;
    }
  }
  &-referral {
    color: rgb(74, 74, 74);

    max-width: 55rem;
    margin: 2rem;

    @media screen and (min-width: 550px) {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 549px) {
      max-width: 90vw;
      margin: unset;
    }



    &-header {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
    &-code {
      background: rgba(0, 0, 0, 0.03);
      border-radius: 3px;
      font-weight: bold;
      color: $pink;
      padding: 1rem;
      text-align: center;
      margin-left: 1rem;

      &-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 4rem;
      }
    }

    &-share {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 3rem;
    }
  }

  &-reset-password {
    max-width: 45rem;
    margin: 2rem;
    @media screen and (min-width: 550px) {
      width: 450px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 549px) {
      margin: unset;
    }


    &-forgot-text {
      @media #{$mediumDown} {
        display: none;
      }
      &-mobile {
        @media #{$mediumUp} {
          display: none;
        }
      }
    }

    &-buttons {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 2rem;
      margin-bottom: 6rem;
      margin-top: 3rem;
    }

    &-button {
      color: white;
      font-weight: bold;
      padding: 1rem 3rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &-cancel {
        background: black;
      }
      &-save {
        background: $pink;
        border: 0;
        padding: 0;
      }
    }
  }

  &-rejoin-button {
    display: inline-block;
    padding: 1rem 2rem;
    color: $white;
    background-color: $pink;
    border: none;
    margin-top: 2rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.CollectionChecklist {
  &-header {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    max-width: 80vw;
    gap: 2rem;
    text-align: center;
    place-content: center;

    margin: 3rem auto;

    &-text {
      font-weight:bold;
      color:#{$pink};
      word-break: keep-all;

    }

    &-hr {
      margin-top: 0.5rem;
      border-top: 1px solid #{$pink};
      width: auto;
      height: 1px;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-auto-flow: column;
    max-width: 60vw;
    column-gap: 3rem;
    text-align: right;
    margin: 0 auto;

    @media screen and (max-width: 549px) {
      grid-template-columns: auto;
      grid-auto-flow: row;
      row-gap: 1rem;
      text-align: left;

    }

  }

  &-item {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-flow: column;
    max-width: 60vw;
    gap: 1rem;
    text-align: left;

    @media screen and (max-width: 549px) {
      grid-template-columns: 32px auto;
      grid-gap: 1rem;
      text-align: left;
    }

    &-icon {
      margin-top: -0.5rem;

    }

    &-text {
      @media screen and (max-width: 549px) {
        margin-left: 1rem;
      }
    }
  }



}


.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
}

.alert-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: center;
  z-index: 999;
}

.alert-button {

  display: block;
  cursor: pointer;
  margin: 20px auto ;
  padding: 5px 25px;
  border-radius: 4px;
  background-color: $pink;
  color:white;
  min-width: 120px;
  min-height:40px;

  @media screen and (max-width: 440px){
    width:90%;
  }
}

.alert  {
  background-color: white;
  color: black;
  font-size:large;
  text-align: center;
  align-items: center;
  font-family: #{$font-content};

  box-sizing: border-box;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: solid gray 1px;

  max-width: 460px;
  width: 85vw;
  height:250px;
  z-index:1000;

  position: relative;
  top: 45%;
  transform: translate(0, -50%);

}

.alert-message  {
  padding-top: 30px;
  padding-bottom: 10px;
}

.alert-message-body {
  margin-top: 20px;
  margin-bottom: 15px;
  color: black;

  @media screen and (max-width: 440px){
    font-size: 90%;
  }

  @media screen and (max-width: 375px){
    font-size: 80%;
  }
}

.svg-black-to-pink {
    filter: invert(20%) sepia(92%) saturate(6024%) hue-rotate(318deg) brightness(99%) contrast(107%);
}
