// HEADER FONT
@font-face {
  font-family: 'DrukXCondensed-Super';
  src: url('static/fonts/DrukXCondensed-Super/DrukXCondensed-Super.eot')
    format('embedded-opentype');
  src: url('static/fonts/DrukXCondensed-Super/DrukXCondensed-Super.woff')
      format('woff'),
    url('static/fonts/DrukXCondensed-Super/DrukXCondensed-Super.ttf')
      format('truetype'),
    url('static/fonts/DrukXCondensed-Super/DrukXCondensed-Super.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk-Bold';
  src: url('static/fonts/Druk-Bold/Druk-Bold.eot') format('embedded-opentype');
  src: url('static/fonts/Druk-Bold/Druk-Bold.woff') format('woff'),
    url('static/fonts/Druk-Bold/Druk-Bold.ttf') format('truetype'),
    url('static/fonts/Druk-Bold/Druk-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

// CONTENT FONT
@font-face {
  font-family: 'MaisonNeue-Book';
  src: url('static/fonts/MaisonNeue-Book/MaisonNeue-Book.eot');
  src: url('static/fonts/MaisonNeue-Book/MaisonNeue-Book.eot')
      format('embedded-opentype'),
    url('static/fonts/MaisonNeue-Book/MaisonNeue-Book.woff') format('woff'),
    url('static/fonts/MaisonNeue-Book/MaisonNeue-Book.ttf') format('truetype'),
    url('static/fonts/MaisonNeue-Book/MaisonNeue-Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Medium';
  src: url('static/fonts/MaisonNeue-Medium/MaisonNeue-Medium.eot');
  src: url('static/fonts/MaisonNeue-Medium/MaisonNeue-Medium.eot')
      format('embedded-opentype'),
    url('static/fonts/MaisonNeue-Medium/MaisonNeue-Medium.woff') format('woff'),
    url('static/fonts/MaisonNeue-Medium/MaisonNeue-Medium.ttf')
      format('truetype'),
    url('static/fonts/MaisonNeue-Medium/MaisonNeue-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue-Bold';
  src: url('static/fonts/MaisonNeue-Bold/MaisonNeue-Bold.eot');
  src: url('static/fonts/MaisonNeue-Bold/MaisonNeue-Bold.eot')
  format('embedded-opentype'),
  url('static/fonts/MaisonNeue-Bold/MaisonNeue-Bold.woff') format('woff'),
  url('static/fonts/MaisonNeue-Bold/MaisonNeue-Bold.ttf')
  format('truetype'),
  url('static/fonts/MaisonNeue-Bold/MaisonNeue-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AmericanTypewriter-Regular';
  src: url('static/fonts/AmericanTypewriter-Regular/AmericanTypewriter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
