@import '../../base/variables';
@import '../../base/media';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  @media #{$mobile} {
    margin-bottom: 2rem;
  }

  .title {
    @media #{$mediumDown} {
      margin-top: 3rem;
    }
  }

  .itemContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 8px;
    border: solid 1px #a0a0a0;
    overflow: hidden;
    box-sizing: border-box;

    .radioButton {
      flex: 1;
    }

    &:hover {
      cursor: pointer;
      user-select: none;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .itemContainerSelected {
    box-shadow: -1px 0 $pink, 1px 0 $pink, 0 -1px 0 $pink, 0 1px 0 $pink;
    border: 1px solid $pink;
  }

  .topInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;

    @media #{$mobile} {
      margin: 1rem 0.5rem;
    }
  }

  .amountPerMonth {
    font-size: 1rem;
    white-space: nowrap;

    .amountPerMonthNumber {
      font-size: 1.5rem;
      font-weight: bold;

      @media #{$mobile} {
        font-size: 1.25rem;
      }
    }
  }

  .totalAmount {
    font-size: 1rem;
    color: $label-grey;
    margin: 0 1rem 1rem 1rem;
    text-align: end;

    @media #{$mobile} {
      margin: 0 0.5rem 0.5rem 0.5rem;
    }
  }

  .mostPopularContainer {
    background: $pink;
    align-self: flex-start;
    padding: 0.3rem 1rem;
    border-bottom-right-radius: 4px;

    span {
      font-size: 0.75rem;
      color: white;
      font-family: $font-content-bold;
      font-weight: bold;

      @media #{$mobile} {
        font-size: 0.6rem;
      }
    }
  }
}
