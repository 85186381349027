@import "./_variables.scss";
@import "./media.scss";

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
:root {
  --pink: #ff4074;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body,
#___gatsby {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  color: $font-color-dark;
  font-family: $font-content;
  font-size: 16px;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.tablet-down {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.bg-pink-gradient {
  background-image: radial-gradient(circle at 10% 0%, #bf0268, #ff4074);
}

.pink {
  color: $pink;
}

.black {
  color: $black;
}

.bg-pink {
  color: $white;
  background-color: $pink;
}

.bg-light-concrete {
  background: url("./base/bg-concrete-light-grey-sm.jpg");
}

.bg-black {
  background-color: $black !important;
}

.text-pink-gradient {
  background: -webkit-linear-gradient(#bf0268, #ff4074);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rotate-deg {
  transform: rotate(-1deg);
}

.mw85 {
  max-width: 72rem;
}

@media (max-width: 768px) {
  .mobile-hidden {
    display: none;
  }
}

.divider {
  background: $border-grey;
  width: 100vw;
  height: 1px;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.link {
  color: $pink;
  font-weight: 600;
}

.responsive-break {
  @media #{$regularDown} {
    display: none;
  }
}

.reverse-responsive-break {
  @media #{$regularUp} {
    display: none;
  }
}
