@import "../../base/variables.scss";
@import "../../base/media";

.Form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &-input {
    padding: 1rem;
    border: 1px solid $form-border;
    background: $white;
    border-radius: 4px;
    width: 100%;
    color: $input-grey;
    outline: none;
    max-height: 3rem;

    &-disabled {
      color: #828282;
    }

    &:focus {
      box-shadow: 0 0 2px 1px $pink;
    }

    &::placeholder {
      color: $form-border;
    }
  }

  &-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 59em) {
      flex-direction: column;
    }

    &-wrapper {
      margin-top: 0.25rem;
      display: flex;
      flex: 1;

      @media screen and (max-width: 59em) {
        width: 100%;
        margin-right: 0;
      }
    }

    &-separator {
      height: 4rem;

      @media #{$mobile} {
        height: 2rem;
      }
    }
  }

  &-label {
    margin-bottom: 0.2rem;
    padding-bottom: 0.2rem;
    display: flex;
    text-align: left;
    color: $input-grey;
    align-items: center;
    letter-spacing: 0.02em;
    min-height: 1.5rem;

    &-tooltip {
      height: 1rem;
      margin-left: 0.5rem;
    }
  }

  &-error {
    font-size: 0.75rem;
    color: $pink;
    min-width: 100%;
    width: 100%;
    height: 1.5rem;
    text-align: left;
    margin-top: 0.5rem;
  }
  &-page-error {
    color: $pink;
    min-width: 100%;
    width: 100%;
    min-height: 2rem;
    text-align: center;
    margin-top: -2rem;
    padding-top: 3px;
    padding-bottom: 0.5rem;
  }

  &-submit {
    &-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      @media screen and (max-width: 59em) {
        grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      }
    }
    background: $pink;
    padding: 1rem 0;
    min-width: 15rem;
    border-radius: 4px;
    font-weight: bold;
  }
}

.OrderSummary {
  border: 1px solid $form-border;
  border-radius: 10px;
  overflow: hidden;
  font-family: $font-content-light;

  &-tooltip {
    margin-top: -1.5rem;
    margin-left: 0.3rem;
  }

  &-tooltip-low {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }

  &-line {
    color: $input-grey;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 3rem;
  }

  &-cost {
    @extend .OrderSummary-line;
    border-bottom: 1px solid $form-border;
    align-items: center;

    &-short {
      @extend .OrderSummary-cost;
      grid-template-columns: 1fr 2rem 1rem;
      @media #{$mobile} {
        grid-template-columns: 1fr 3rem;
        font-size: 85%;
      }
    }

    &-value {
      display: flex;
      justify-content: flex-end;
    }

    &-label-wrapper {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
    }

    &-size {
      font-size: 0.875rem;
      margin-top: 0.5rem;
    }

    &-label {
      display: flex;
      align-items: center;
      color: inherit;
      font-family: inherit;
      line-height: 1.4;
    }

    &-price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold;

      &-discount {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        font-weight: bold;
      }
    }

    &-minusPrice {
      @extend .OrderSummary-cost-price;
      &::before {
        content: "-";
      }
    }

    &-originalPrice {
      text-decoration: line-through 2px;
      padding-right: 0.5rem;
    }

    &-per {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }

    &-duration {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold;
      white-space: nowrap;
    }

    &-wrapper {
      background-color: rgb(248, 249, 250);
      display: grid;
      grid-auto-flow: row;
      gap: 2px;
      padding: 0.5rem 3rem 0.5rem 3rem;
      @media #{$mobile} {
        padding: 0.5rem 3vw 0.5rem 3vw;
      }
    }
  }
  &-placeholder {
    height: 230px;
    background-color: rgb(248, 249, 250);
    padding: 2rem;
  }

  &-discount {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 25% auto;
    @media #{$mobile} {
      grid-template-columns: 30% auto;
    }
  }

  &-total {
    @extend .OrderSummary-line;
    grid-template-rows: 4rem;
    padding: 0 3rem 0 3rem;
    @media #{$mobile} {
      grid-template: 4rem / 1fr 2rem;
      padding: 0 3vw 0 3vw;
    }

    &-short {
      @extend .OrderSummary-total;
      grid-template-columns: 1fr 2rem 1rem;
      @media #{$mobile} {
        grid-template-columns: 1fr 2rem;
        padding: 0 3vw 0 3vw;
        font-size: 85%;
      }
    }
  }

  &-bottomNote {
    font-size: 12px;
    font-weight: 500;
    color: #737373;
    margin-top: 1rem;
    white-space: pre-line;
    line-height: 1.4;
  }
}

.hidden {
  display: none;
}

input[type="date"] {
  height: 3rem;
  padding: 1rem;
  text-transform: uppercase;
}

input[type="checkbox"] {
  appearance: none;

  height: 1rem;
  min-height: 1rem;
  max-height: 1rem;
  width: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  border: 1px solid silver;
  border-radius: 5px;
  outline: none;

  &:checked {
    background-color: $pink;
    color: white;
    border-color: $pink;

    &:after {
      position: relative;
      margin: auto;
      font-size: 0.75rem;
      content: "\2714";
      top: -2px;
      left: 2px;
    }
  }
}
