.ButtonLink {
  color: white;
  border: none;
  line-height: 1;
//  padding: 18px 16px;
  display: inline-block;
  cursor: pointer;

  &-text {
    width:115px;
    height: 2.3rem;
    vert-align: bottom;
    text-align: center;
    padding-top: 0.7rem;
    text-transform: uppercase;

    @media screen and (max-width: 959px) {
      width:100%;
      text-transform: capitalize;
      color:black;
      text-align:left;
    }
  }
}
