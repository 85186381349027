@import '../../base/media';
@import '../../base/variables';

.container {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex: 1;
}
