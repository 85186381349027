@import '../../../base/variables';
@import '../../../base/media';

.container {
  display: flex;
  flex-direction: row;

  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
  }

  .rightInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .name {
      font-size: 1rem;
      font-family: $font-content-light;
    }

    .publishedAt {
      font-size: 0.75rem;
      font-family: $font-content-light;
    }
  }
}
