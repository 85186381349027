@import '../../../base/variables';
@import '../../../base/media';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .title {
    font-size: 3rem;
    letter-spacing: 2px;
    margin-bottom: 1rem;
    font-family: $font-content-bold;

    @media #{$mobile} {
      font-size: 2rem;
    }
  }

  .excerpt {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    font-family: $font-content-light;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media #{$mobile} {
      font-size: 1rem;
    }
  }

  .coverImage {
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    margin-bottom: 3rem;

    @media #{$mobile} {
      margin-bottom: 2rem;
      max-height: 50vh;
    }
  }

  .readMoreButton {
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      height: auto;
      object-fit: cover;
      margin-right: 1rem;
    }

    span {
      font-size: 1.2rem;
      color: $pink;

      @media #{$mobile} {
        font-size: 1rem;
      }
      user-select: none;
      -webkit-user-select: none;
    }
  }
}
