@import '../../../base/variables';
@import '../../../base/media';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem 0;
  border-top: 1px solid #e4e4e4;

  .authorContainer {
    display: flex;
    flex-direction: row;

    .authorAvatar {
      width: 6.25rem;
      height: 6.25rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1rem;

      @media #{$mobile} {
        width: 4rem;
        height: 4rem;
      }
    }

    .authorInfo {
      flex: 1;
      display: flex;
      flex-direction: column;

      .authorLabel {
        font-size: 1rem;
        color: #757570;
        font-family: $font-typewriter;
        margin-bottom: 0.6rem;

        @media #{$mobile} {
          font-size: 0.8rem;
          margin-bottom: 0.3rem;
        }
      }

      .authorName {
        font-family: $font-content;
        font-weight: bold;
        font-size: 1.375rem;
        margin-bottom: 0.6rem;

        @media #{$mobile} {
          font-size: 1rem;
          margin-bottom: 0.3rem;
        }
      }

      .authorDescription {
        font-family: $font-content;
        font-size: 1rem;
        line-height: 1.4;

        @media #{$mobile} {
          font-size: 0.9rem;
        }
      }
    }
  }
}
