@import '../../base/variables';
@import '../../base/media';

.CookieBanner {
  width: 100vw;
  z-index: 200;
  padding: 2rem 2rem;
  background: black;
  position: fixed;
  bottom: 0;
  color: white;
  display: flex;
  border-top: solid 1px $pink;
  @media screen and (min-width: 400px) {
    padding: 2rem 8rem 2rem 2rem ;
  }

  @media #{$mobile} {
    padding: 1rem;
  }

  &-agree {
    background: $pink;
    margin-top: 1rem;
    text-transform: uppercase;
    width: 7rem;
    max-height: 3rem;
    text-align: center;
    padding: 1rem 0;
    font-weight: bold;

  }

  &-submit {
    &-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      @media screen and (max-width: 59em) {
        grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  }
}
    background: $pink;
    padding: 1rem 0;
    min-width: 15rem;
    border-radius: 4px;
    font-weight: bold;
  }

}
