@import '../../base/media';
@import '../../base/variables';

footer {
  background: black;
  h4,
  p,
  a {
    color: #fff;
  }

  h4 {
    font-family: $font-header;
    font-size: 46px !important;
    font-weight: normal;
  }

  .useful-links {
    a:nth-child(3) {
      order: 2;

      @media screen and (min-width: 60rem) {
        order: 3;
      }
    }
    a:nth-child(2) {
      order: 3;
      @media screen and (min-width: 60rem) {
        order: 2;
      }
    }
  }

  .social {
    margin-right: 10px;
    img {
      height: 22px;
    }
  }

  .justify-none {
    @media screen and (min-width: 30rem) {
      justify-content: flex-start;
    }
  }

  .newsletter {
    height: 2.5rem;
    input {
      padding: 5px;
    }
    input:focus {
      outline: none;
    }
    .submit {
      height: 20px;
      button {
        position: relative;
        width: 100%;
        height: 100%;

        cursor: pointer;
        &:focus {
          outline: none;
        }
        img {
          position: absolute;
          height: 15px;
          right: 6px;
          top: 3px;
        }
      }
    }
    p {
      color: pink;
    }
  }

  .border-footer {
    border-top: solid 1px rgba(255, 255, 255, 0.7);
    position: relative;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;
    @media #{$mediumDown} {
      flex-direction: column;
    }
  }
  .footer-fca {
    font-size: 0.75rem;
    margin-left: 2rem;
  }
  .footer-copyright {
    margin-right: 2rem;
    min-width: 125px;
    @media #{$mediumDown} {
      margin-top: 1rem;
    }
  }
}
