@import "../../base/variables";
@import "../../base/media";

.PreFooter {
  margin-top: -30px;
  background: url(../../static/images/prefooter-sign-up.png);

  .prefooter__content > div {
    flex-direction: row;

    @media #{$regularDown} {
      flex-direction: column;
    }
  }

  @media #{$mobileUp} {
    background: none;
  }

  .img-shape {
    display: block;
  }

  .headerText {
    @media #{$regularDown} {
      padding-bottom: 1.6rem;
    }

    @media #{$mobile} {
      font-size: 4rem;
    }
  }

  &-link {
    display: inline-block;
    padding: 1rem 2rem;
    background: $black;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;

    @media #{$regularDown} {
      width: 90vw;
      text-align: center;
      font-size: 1rem;
    }
  }

  input {
    height: 100%;
    padding: 16px;
    border-radius: 5px 0 0 5px;
    border: none;
    @media #{$regular} {
      width: 300px;
    }

    @media #{$regularDown} {
      display: none;
    }
  }

  .prefooter__content {
    background: #ff4074;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .svg-container {
    position: relative;
    display: block;
    height: 29px;
  }

  svg:not(:root) {
    overflow: hidden;
    height: 30px;
    width: 100%;
  }

  h2 {
    font-weight: normal;
    line-height: 0.9;
  }

  h4,
  p {
    color: black;
  }
}
