@import '../../../base/media';
@import '../../../base/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35rem;
  border: solid 1px #a0a0a0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  @media #{$mediumDown} {
    height: 27rem;
  }

  .topContent {
    .image {
      width: 100%;
      height: 13rem;
      margin-bottom: 1rem;
      background-color: #f5f5f5;
      object-fit: contain;
      padding: 1rem;

      @media #{$mediumDown} {
        height: 7rem;
      }
    }

    .nameContainer {
      display: flex;
      margin: 0 1rem;

      @media #{$mediumDown} {
        margin: 0 0.5rem;
      }

      .name {
        font-family: $font-content-light;
        font-size: 1.25rem;
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        -webkit-box-orient: vertical;
        flex: 1;

        @media #{$mediumDown} {
          font-size: 1.1rem;
        }
      }

      .infoIcon {
        color: white;
        font-weight: bold;
        font-size: 10px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-radius: 50%;
        background-color: $pink;
        text-align: center;
        user-select: none;
        vertical-align: text-top;
      }
    }

  }

  .sizesContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1rem;

    @media #{$mediumDown} {
      margin: 0 0.5rem;
    }

    .sizeButton {
      flex: 1;
      &:not(:last-child){
        margin-right: 1rem;

        @media #{$mediumDown} {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 1rem;
    padding-bottom: 1rem;

    @media #{$mediumDown} {
      margin: 0 0.5rem;
      padding-bottom: 0.5rem;
    }

    .rrpPrice {
      font-size: 1rem;
      font-weight: 500;
      color: #737373;
      margin-bottom: 1rem;
      text-decoration: line-through;

      @media #{$mediumDown} {
        margin-bottom: 0.5rem;
      }
    }

    .price {
      font-size: 1rem;
      color: $pink;
      font-weight: 500;
      margin-bottom: 1rem;
      line-height: 1.4;

      @media #{$mediumDown} {
        margin-bottom: 0.5rem;
      }
    }

    .addButton {
      background-color: $pink;
      padding: 1rem 0;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          user-select: none;
          opacity: 0.5;
        }
      }

      span {
        color: white;
        font-weight: bold;
        font-size: 1rem;

        @media #{$mediumDown} {
          font-size: 0.9rem;
        }
      }
    }

    .placeholderAddButton {
      @extend .addButton;

      background-color: transparent;

      @media (hover: hover) {
        &:hover {
          cursor: default;
          opacity: 1;
        }
      }
    }
  }
}

.selectedContainer {
  box-shadow: -1px 0 $pink, 1px 0 $pink, 0 -1px 0 $pink, 0 1px 0 $pink;
  border: 1px solid $pink;

  .selectedAddButton {
    box-shadow: -1px 0 $pink, 1px 0 $pink, 0 -1px 0 $pink, 0 1px 0 $pink;
    background-color: white !important;

    span {
      color: $pink !important;
    }
  }
}

.tickIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: $pink;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;

  @media #{$mediumDown} {
    top: 0.3rem;
    right: 0.3rem;
    width: 20px;
    height: 20px;
    padding: 3px;
  }
}

.tooltipContent {
  font-size: 1rem;
  font-weight: 500;
}
